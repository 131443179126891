export const colours = {
  greys: {
    1: "hsl(240, 70%, 98%)",
    2: "hsl(240, 70%, 95%)",
    3: "hsl(240, 10%, 90%)",
    5: "hsl(240, 10%, 80%)",
    6: "hsl(240, 10%, 70%)",
    7: "hsl(240, 10%, 55%)",
    8: "hsl(240, 10%, 35%)",
    9: "hsl(240, 10%, 25%)",
  },
  primary: {
    1: "hsl(204, 100%, 95%)",
    3: "hsl(207, 95%, 50%)",
    5: "hsl(211, 90%, 45%)",
    7: "hsl(207, 80%, 40%)",
    8: "hsl(207, 70%, 45%)",
    9: "hsl(207, 90%, 30%)",
  },
  positive: {
    1: "hsl(120, 100%, 95%)",
    3: "hsl(120, 80%, 80%)",
    5: "hsl(120, 90%, 45%)",
    7: "hsl(120, 80%, 40%)",
    8: "hsl(120, 85%, 35%)",
    9: "hsl(120, 90%, 30%)",
  },
  negative: {
    1: "hsl(0, 100%, 95%)",
    3: "hsl(0, 80%, 80%)",
    5: "hsl(0, 90%, 45%)",
    7: "hsl(0, 80%, 40%)",
    8: "hsl(0, 70%, 45%)",
    9: "hsl(0, 90%, 30%)",
  },
};
