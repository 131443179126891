import React, { useState } from "react";
import MuiTextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { Results } from "./Results";

const TextField = styled(MuiTextField)`
  && {
    width: 500px;
    max-width: 95%;
    margin: 4rem 0.5rem 2rem 0.5rem;

    input::-webkit-search-cancel-button {
      cursor: pointer;
    }
  }
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <Root>
      <TextField
        label="Search foods"
        type="search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Results searchTerm={searchTerm} />
    </Root>
  );
}

export default App;
