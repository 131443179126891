let data = [
  {
    name: "Garlic",
    limit: "",
    isHigh: true,
    notes: "avoid entirely if possible\nIncludes garlic salt, garlic powder",
    categories: "",
    keywords: ""
  },
  {
    name: "Onions",
    limit: "",
    isHigh: true,
    notes: "avoid entirely if possible\nIncludes onion powder, small pickled onions\nTry Hing / Asafoetida powder or garlic oil to substitute",
    categories: "",
    keywords: ""
  },
  {
    name: "Artichoke",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Asparagus",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Baked beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Beetroot, fresh",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Black eyed peas",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Broad beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Butter beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cassava",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cauliflower",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Celery",
    limit: "",
    isHigh: true,
    notes: "greater than 5cm of stalk",
    categories: "",
    keywords: ""
  },
  {
    name: "Choko",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Falafel",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fermented cabbage e.g. sauerkraut",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Haricot beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kidney beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lima beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Leek bulb",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mange Tout",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mixed vegetables",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mung beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mushrooms",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Peas, sugar snap",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pickled vegetables",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Red kidney beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Savoy Cabbage",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Soy beans / soya beans",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Split peas",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Scallions / spring onions",
    limit: "",
    isHigh: true,
    notes: "Only bulb / white part is high",
    categories: "",
    keywords: ""
  },
  {
    name: "Shallots",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Taro",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Apples",
    limit: "",
    isHigh: true,
    notes: "Includes pink lady and granny smith",
    categories: "",
    keywords: ""
  },
  {
    name: "Apricots",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Avocado",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bananas",
    limit: "",
    isHigh: true,
    notes: "High in FODMAPs when ripe",
    categories: "",
    keywords: ""
  },
  {
    name: "Blackberries",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Blackcurrants",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Boysenberry",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cherries",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Currants",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Custard apple",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Dates",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Feijoa",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Figs",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Goji berries",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Grapefruit",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Guava",
    limit: "",
    isHigh: true,
    notes: "High when unripe",
    categories: "",
    keywords: ""
  },
  {
    name: "Lychee",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mango",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Nectarines",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Paw paw, dried",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Peaches",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pears",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Persimmon",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pineapple, dried",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Plums",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pomegranate",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Prunes",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Raisins",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sea buckthorns",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sultanas",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tamarillo",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tinned fruit in apple / pear juice",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Watermelon",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chorizo",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sausages",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Biscuits / cookies including chocolate chip cookies",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Bread, wheat",
    limit: "",
    isHigh: true,
    notes: "over 1 slice",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Breadcrumbs",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Cakes",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Cereal bar, wheat based",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Croissants",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Crumpets",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Egg noodles",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Muffins",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Pastries",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Pasta, wheat",
    limit: "over 1/2 cup cooked",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Udon noodles",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat bran",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat cereals",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat germ",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat noodles",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Wheat rolls",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Almond meal",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Amaranth flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Barley including flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Bran cereals",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Granary bread",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Multigrain bread",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Naan",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Oatmeal bread",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Pumpernickel bread",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Roti",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Sourdough with kamut",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Bread",
    keywords: "Wheat"
  },
  {
    name: "Cashews",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Nuts",
    keywords: ""
  },
  {
    name: "Chestnut flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Nuts Flour",
    keywords: ""
  },
  {
    name: "Cous cous",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Einkorn flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Flour",
    keywords: ""
  },
  {
    name: "Freekeh",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Gnocchi",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: "Wheat"
  },
  {
    name: "Granola bar",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Muesli cereal",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Muesli bar",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pistachios",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rye",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rye crispbread",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Semolina",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Spelt flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Agave",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Caviar dip",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fructose",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fruit bar",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Gravy, if it contains onion",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "High fructose corn syrup (HFCS)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Hummus / houmous",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Honey",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Jam, mixed berries",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Jam, strawberry, if contains HFCS",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Molasses",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pesto sauce",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Quince paste",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Relish / vegetable pickle",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Stock cubes",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sugar free sweets containing polyols",
    limit: "",
    isHigh: true,
    notes: "usually ending in -ol or isomalt",
    categories: "",
    keywords: ""
  },
  {
    name: "Inulin",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Isomalt (E953 / 953)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Lactitol (E966 / 966)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Maltitol (E965 / 965)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Mannitol (E241 / 421)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Sorbitol (E420 / 420)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Xylitol (E967 / 967)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "Sweeteners",
    keywords: ""
  },
  {
    name: "Tahini paste",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tzatziki dip",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "FOS - fructooligosaccharides",
    limit: "",
    isHigh: true,
    notes: "May be hiding in yoghurts, snack bars etc.",
    categories: "",
    keywords: ""
  },
  {
    name: "Inulin",
    limit: "",
    isHigh: true,
    notes: "May be hiding in yoghurts, snack bars etc.",
    categories: "",
    keywords: ""
  },
  {
    name: "Oligofructose",
    limit: "",
    isHigh: true,
    notes: "May be hiding in yoghurts, snack bars etc.",
    categories: "",
    keywords: ""
  },
  {
    name: "Beer",
    limit: "1 bottle",
    isHigh: true,
    notes: "if drinking more than one bottle",
    categories: "",
    keywords: ""
  },
  {
    name: "Coconut water",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cordial, apple and raspberry with 50-100% real juice",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cordial, orange with 25-50% real juice",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fruit and herbal teas with apple added",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fruit juices in large quantities",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fruit juices made of apple, pear, mango",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kombucha",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Malted chocolate flavored drink",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Meal replacement drinks containing milk based products",
    limit: "",
    isHigh: true,
    notes: "e.g. Ensure, Slim Fast",
    categories: "",
    keywords: ""
  },
  {
    name: "Orange",
    limit: "over 100ml",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Quinoa milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rum",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sodas containing High Fructose Corn Syrup (HFCS)",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Soy milk made with soy beans",
    limit: "",
    isHigh: true,
    notes: "commonly found in USA",
    categories: "",
    keywords: ""
  },
  {
    name: "Sports drinks",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tea:\nBlack tea with added soy milk\nChai tea, strong\nDandelion tea, strong\nFennel tea\nChamomile tea\nHerbal tea, strong\nOolong tea",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Black tea with added soy milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chai tea, strong",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Dandelion tea, strong",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fennel tea",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chamomile tea",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Herbal tea, strong",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oolong tea",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Wine",
    limit: "",
    isHigh: true,
    notes: "if drinking more than one glass",
    categories: "",
    keywords: ""
  },
  {
    name: "Whey protein, concentrate unless lactose free",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Whey protein, hydrolyzed unless lactose free",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Buttermilk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cheese, cream",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cheese, Halmoumi",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cheese, ricotta",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cream",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Custard",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Gelato",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Ice cream",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kefir",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Milk:\nCow milk\nGoat milk\nEvaporated milk\nSheep’s milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cow milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Goat milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Evaporated milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sheep’s milk",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sour cream",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Yoghurt",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Carob powder / carob flour",
    limit: "",
    isHigh: true,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Alfalfa",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bamboo shoots",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bean sprouts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Beetroot, canned and pickled",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Black beans",
    limit: "",
    isHigh: false,
    notes: "1/4 cup / 45g",
    categories: "",
    keywords: ""
  },
  {
    name: "Bok choy / pak choi",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccoli, whole",
    limit: "",
    isHigh: false,
    notes: "1/2 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccoli, heads only",
    limit: "",
    isHigh: false,
    notes: "3/4 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccoli, stalks only",
    limit: "",
    isHigh: false,
    notes: "1/2 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccolini, whole",
    limit: "",
    isHigh: false,
    notes: "1/2 cup chopped",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccolini, heads only",
    limit: "",
    isHigh: false,
    notes: "1/2 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Broccolini, stalks only",
    limit: "",
    isHigh: false,
    notes: "1 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Brussels sprouts",
    limit: "",
    isHigh: false,
    notes: "2 sprouts",
    categories: "",
    keywords: ""
  },
  {
    name: "Butternut squash",
    limit: "",
    isHigh: false,
    notes: "1/4 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Cabbage, common and red up to 1 cup",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Callaloo",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Carrots",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Celeriac",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Celery",
    limit: "",
    isHigh: false,
    notes: "less than 5cm of stalk",
    categories: "",
    keywords: ""
  },
  {
    name: "Chicory leaves",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chick peas",
    limit: "",
    isHigh: false,
    notes: "1/4 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Chilli",
    limit: "",
    isHigh: false,
    notes: "if tolerable",
    categories: "",
    keywords: ""
  },
  {
    name: "Chives",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cho cho",
    limit: "",
    isHigh: false,
    notes: "1/2 cup diced",
    categories: "",
    keywords: ""
  },
  {
    name: "Choy sum",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Collard greens",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Corn / sweet corn",
    limit: "",
    isHigh: false,
    notes: "if tolerable and only in small amounts - 1/2 cob",
    categories: "",
    keywords: ""
  },
  {
    name: "Courgette",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cucumber",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Eggplant / aubergine",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fennel",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Green beans",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Green pepper / green bell pepper / green capsicum",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Ginger",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kale",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Karela",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Leek leaves",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lentils",
    limit: "",
    isHigh: false,
    notes: "in small amounts",
    categories: "",
    keywords: ""
  },
  {
    name: "Lettuce:\nButter lettuce\nIceberg lettuce\nRadicchio lettuce\nRed coral lettuce\nRocket lettuce\nRomaine/Cos lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Butter lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Iceberg lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Radicchio lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Red coral lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rocket lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Romaine/Cos lettuce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Marrow",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Okra",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Olives",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Parsnip",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Peas, snow",
    limit: "",
    isHigh: false,
    notes: "5 pods",
    categories: "",
    keywords: ""
  },
  {
    name: "Pickled gherkins",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pickled onions, large",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Potato",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pumpkin",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pumpkin, canned",
    limit: "",
    isHigh: false,
    notes: "1/4 cup, 2.2 oz",
    categories: "",
    keywords: ""
  },
  {
    name: "Radish",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Red peppers / red bell pepper / red capsicum",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Scallions / spring onions (green part)",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Seaweed / nori",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Silverbeet / chard",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Spaghetti squash",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Spinach, baby",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Squash",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sun-dried tomatoes",
    limit: "",
    isHigh: false,
    notes: "4 pieces",
    categories: "",
    keywords: ""
  },
  {
    name: "Swede",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Swiss chard",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sweet potato",
    limit: "",
    isHigh: false,
    notes: "1/2 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Tomato",
    limit: "",
    isHigh: false,
    notes: "canned, cherry, common, roma",
    categories: "",
    keywords: ""
  },
  {
    name: "Tomatillos",
    limit: "",
    isHigh: false,
    notes: "canned",
    categories: "",
    keywords: ""
  },
  {
    name: "Turnip",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Water chestnuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Yam",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Zucchini",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Ackee",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bananas, unripe",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bilberries",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Blueberries",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Breadfruit",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Carambola",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cantaloupe",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cranberry",
    limit: "",
    isHigh: false,
    notes: "1 tbsp",
    categories: "",
    keywords: ""
  },
  {
    name: "Clementine",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Dragon fruit",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lingonberries",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Grapes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Guava, ripe",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Honeydew and Galia melons",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kiwifruit",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lemon including lemon juice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lime including lime juice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mandarin",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Orange",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Passion fruit",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Paw paw",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Papaya",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pineapple",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Plantain, peeled",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Prickly pear / nopales",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Raspberry",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rhubarb",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Strawberry",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tamarind",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tangelo",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Beef",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chicken",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Foie gras",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Kangaroo",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lamb",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pork",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Prosciutto",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Quorn, mince",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Turkey",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cold cuts / deli meat / cold meats such as ham and turkey breast",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Processed meat",
    limit: "",
    isHigh: false,
    notes: "check ingredients",
    categories: "",
    keywords: ""
  },
  {
    name: "Canned tuna",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fresh fish e.g.\nCod\nHaddock\nPlaice\nSalmon\nTrout\nTuna",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cod",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Haddock",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Plaice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Salmon",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Trout",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tuna",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Seafood (ensuring nothing else is added) e.g.\nCrab\nLobster\nMussels\nOysters\nPrawns\nShrimp",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Crab",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lobster",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mussels",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oysters",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Prawns",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Shrimp",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Wheat free breads",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Gluten free breads",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bread:\nCorn bread\nRice bread\nSpelt sourdough bread\nPotato flour bread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Corn bread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice bread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Spelt sourdough bread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Potato flour bread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Wheat free or gluten free pasta",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bread, wheat",
    limit: "",
    isHigh: false,
    notes: "1 slice",
    categories: "",
    keywords: ""
  },
  {
    name: "Almonds",
    limit: "",
    isHigh: false,
    notes: "max of 15",
    categories: "",
    keywords: ""
  },
  {
    name: "Biscuit, savoury",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Biscuit, shortbread",
    limit: "",
    isHigh: false,
    notes: "1 only",
    categories: "",
    keywords: ""
  },
  {
    name: "Brazil nuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Bulgur / bourghal",
    limit: "",
    isHigh: false,
    notes: "1/4 cup cooked, 44g serving",
    categories: "",
    keywords: ""
  },
  {
    name: "Buckwheat",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Buckwheat flour",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Buckwheat noodles",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Brown rice / whole grain rice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chestnuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chips, plain / potato crisps, plain",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cornflour / maize",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Crispbread",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Corncakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cornflakes",
    limit: "",
    isHigh: false,
    notes: "1/2 cup",
    categories: "",
    keywords: ""
  },
  {
    name: "Cornflakes, gluten free",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Coconut",
    limit: "",
    isHigh: false,
    notes: "milk, cream, flesh",
    categories: "",
    keywords: ""
  },
  {
    name: "Corn, creamed and canned (up to 1/3 cup)",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Corn tortillas, 3 tortillas",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Crackers, plain",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Flax seeds / linseeds",
    limit: "",
    isHigh: false,
    notes: "up to 1 tbsp",
    categories: "",
    keywords: ""
  },
  {
    name: "Hazelnuts",
    limit: "",
    isHigh: false,
    notes: "max of 15",
    categories: "",
    keywords: ""
  },
  {
    name: "Kellogg’s (US):\nCorn Flakes\nCrispix\nFrosted Flakes\nFrosted Krispies\nRice Krispies",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Corn Flakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Crispix",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Frosted Flakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Frosted Krispies",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice Krispies",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Macadamia nuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Millet",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mixed nuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oatmeal, 1/2 cup",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oats",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oatcakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Peanuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pecans",
    limit: "",
    isHigh: false,
    notes: "max of 15",
    categories: "",
    keywords: ""
  },
  {
    name: "Pine nuts",
    limit: "",
    isHigh: false,
    notes: "max of 15",
    categories: "",
    keywords: ""
  },
  {
    name: "Polenta",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Popcorn",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Porridge and oat based cereals",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Potato flour",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pretzels",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Quinoa",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pasta, wheat",
    limit: "",
    isHigh: false,
    notes: "up to 1/2 cup cooked",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice:\nBasmati rice\nBrown rice\nRice noodles\nWhite rice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Basmati rice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Brown rice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice noodles",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "White rice",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice bran",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice cakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice crackers",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice flakes",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice flour",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Seeds:\nChia seeds\nEgusi seeds\nHemp seeds\nPoppy seeds\nPumpkin seeds\nSesame seeds\nSunflower seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chia seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Egusi seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Hemp seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Poppy seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pumpkin seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sesame seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sunflower seeds",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Starch, maize, potato and tapioca",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sorghum",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tortilla chips / corn chips",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Walnuts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Aspartame",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Acesulfame K",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Almond butter",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Barbecue sauce",
    limit: "",
    isHigh: false,
    notes: "check label carefully",
    categories: "",
    keywords: ""
  },
  {
    name: "Capers in vinegar",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Capers, salted",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Chocolate:\nDark chocolate\nMilk chocolate",
    limit: "",
    isHigh: false,
    notes: "3 squares\nWhite chocolate - 3 squares",
    categories: "",
    keywords: ""
  },
  {
    name: "Dark chocolate",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Milk chocolate",
    limit: "",
    isHigh: false,
    notes: "3 squares",
    categories: "",
    keywords: ""
  },
  {
    name: "White chocolate",
    limit: "",
    isHigh: false,
    notes: "3 squares",
    categories: "",
    keywords: ""
  },
  {
    name: "Chutney, 1 tablespoon",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Dijon mustard",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Erythritol (E968 / 968)",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fish sauce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Golden syrup",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Glucose",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Glycerol (E422 / 422)",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Jam / jelly, strawberry",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Ketchup (USA)",
    limit: "",
    isHigh: false,
    notes: "1 sachet",
    categories: "",
    keywords: ""
  },
  {
    name: "Maple syrup",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Marmalade",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Marmite",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mayonnaise",
    limit: "",
    isHigh: false,
    notes: "ensuring no garlic or onion in ingredients",
    categories: "",
    keywords: ""
  },
  {
    name: "Miso paste",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Mustard",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Oyster sauce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Pesto sauce",
    limit: "",
    isHigh: false,
    notes: "less than 1 tbsp",
    categories: "",
    keywords: ""
  },
  {
    name: "Peanut butter",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice malt syrup",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Saccharine",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Shrimp paste",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Soy sauce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sriracha hot chilli sauce",
    limit: "",
    isHigh: false,
    notes: "1 tsp",
    categories: "",
    keywords: ""
  },
  {
    name: "Stevia",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sweet and sour sauce",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sucralose",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Sugar",
    limit: "",
    isHigh: false,
    notes: "also called sucrose",
    categories: "",
    keywords: ""
  },
  {
    name: "Tamarind paste",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tomato sauce (outside USA)",
    limit: "",
    isHigh: false,
    notes: "2 sachets, 13g",
    categories: "",
    keywords: ""
  },
  {
    name: "Vegemite",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Vinegars:\nApple cider vinegar, 2 tbsp\nBalsamic vinegar, 2 tbsp\nRice wine vinegar",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Apple cider vinegar, 2 tbsp",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Balsamic vinegar, 2 tbsp",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Rice wine vinegar",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Wasabi",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Worcestershire sauce",
    limit: "",
    isHigh: false,
    notes: "has onion and garlic but very very low amount making it low FODMAP",
    categories: "",
    keywords: ""
  },
  {
    name: "Alcohol",
    limit: "",
    isHigh: false,
    notes: "is an irritant to the gut, limited intake advised",
    categories: "",
    keywords: ""
  },
  {
    name: "Beer",
    limit: "One drink",
    isHigh: false,
    notes: "limited to one drink",
    categories: "Alcohol",
    keywords: ""
  },
  {
    name: "Clear spirits such as Vodka",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Alcohol",
    keywords: ""
  },
  {
    name: "Gin",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Alcohol",
    keywords: ""
  },
  {
    name: "Whiskey",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Alcohol",
    keywords: ""
  },
  {
    name: "Wine",
    limit: "",
    isHigh: false,
    notes: "limited to one drink",
    categories: "Alcohol",
    keywords: ""
  },
  {
    name: "Coffee",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Espresso coffee, regular or decaffeinated, black",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Coffee",
    keywords: ""
  },
  {
    name: "Espresso coffee, regular or decaffeinated, with up to 250ml lactose free milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Coffee",
    keywords: ""
  },
  {
    name: "Instant coffee, regular or decaffeinated, black",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Coffee",
    keywords: ""
  },
  {
    name: "Instant coffee, regular or decaffeinated, with up to 250ml lactose free milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Coffee",
    keywords: ""
  },
  {
    name: "Drinking chocolate powder",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Fruit juice",
    limit: "125ml",
    isHigh: false,
    notes: "Safe fruits only",
    categories: "",
    keywords: ""
  },
  {
    name: "Kvass",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lemonade",
    limit: "",
    isHigh: false,
    notes: "in low quantities",
    categories: "",
    keywords: ""
  },
  {
    name: "Egg protein",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Pea protein",
    limit: "20g",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Rice protein",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Sacha Inchi protein",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Whey protein isolate",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Soya milk made with soy protein",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Protein powders",
    keywords: ""
  },
  {
    name: "Sugar free fizzy drinks / soft drinks / soda",
    limit: "",
    isHigh: false,
    notes: "such as diet coke, in low quantities as aspartame and acesulfame k can be irritants",
    categories: "",
    keywords: ""
  },
  {
    name: "fizzy drinks / soft drinks / soda (With sugar)",
    limit: "Low amounts",
    isHigh: false,
    notes: "As long as they do no contain HFCS such as lemonade, cola. Limit intake due to these drinks being generally unhealthy and can cause gut irritation.",
    categories: "",
    keywords: ""
  },
  {
    name: "Tea",
    limit: "",
    isHigh: false,
    notes: "Mostly ok if weak",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Black tea, weak e.g. PG Tips",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Chai tea, weak",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Fruit and herbal tea, weak",
    limit: "",
    isHigh: false,
    notes: "ensure no apple added",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Green tea",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Peppermint tea",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "White tea",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Tea",
    keywords: ""
  },
  {
    name: "Water",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Butter",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Hard cheeses",
    limit: "",
    isHigh: false,
    notes: "Generally ok, soft cheeses are not",
    categories: "",
    keywords: ""
  },
  {
    name: "Brie",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Camembert",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Cheddar",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Cottage",
    limit: "2 tablespoons",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Feta",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Goat / chevre",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Monterey Jack",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Mozzarella",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Parmesan",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Ricotta",
    limit: "",
    isHigh: false,
    notes: "2 tablespoons",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Swiss",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Cheese",
    keywords: ""
  },
  {
    name: "Dairy free chocolate pudding",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Eggs",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Margarine",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Milk Alternatives",
    limit: "",
    isHigh: false,
    notes: "Generally ok except Soya",
    categories: "",
    keywords: ""
  },
  {
    name: "Almond milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Hemp milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Lactose free milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Macadamia milk",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Oat milk",
    limit: "30 ml",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Rice milk",
    limit: "200ml",
    isHigh: false,
    notes: "",
    categories: "Milk",
    keywords: ""
  },
  {
    name: "Sorbet",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Soy protein (avoid soya beans)",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Swiss cheese",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tempeh",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Tofu",
    limit: "",
    isHigh: false,
    notes: "drained and firm varieties",
    categories: "",
    keywords: ""
  },
  {
    name: "Whipped cream",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Yoghurt",
    limit: "",
    isHigh: false,
    notes: "Non-dairy or low-lactose varieties",
    categories: "",
    keywords: ""
  },
  {
    name: "Coconut yoghurt",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Yoghurt",
    keywords: ""
  },
  {
    name: "Greek yoghurt, in small amounts",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Yoghurt",
    keywords: ""
  },
  {
    name: "Lactose free yoghurt",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Yoghurt",
    keywords: ""
  },
  {
    name: "Goats yoghurt",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "Yoghurt",
    keywords: ""
  },
  {
    name: "Herbs",
    limit: "",
    isHigh: false,
    notes: "Basil, Bay leaves, Cilantro, Coriander, Curry leaves, Fenugreek, Gotukala, Lemongrass, Mint, Oregano, Pandan, Parsley, Rampa, Rosemary, Sage, Tarragon, Thyme",
    categories: "",
    keywords: ""
  },
  {
    name: "Spices",
    limit: "",
    isHigh: false,
    notes: "All spice, Black pepper, Cardamon, Chilli powder (check ingredients, sometimes has garlic added), Cinnamon, Cloves, Cumin, Curry powder, Fennel seeds, Five spice, Goraka, Mustard seeds, Nutmeg, Paprika, Saffron, Star anise, Turmeric",
    categories: "",
    keywords: ""
  },
  {
    name: "Oils: Avocado oil, Canola oil, Coconut oil, Olive oil, Peanut oil, Rice bran oil, Sesame oil, Soybean oil, Sunflower oil, Vegetable oil",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Garlic infused oil",
    limit: "",
    isHigh: false,
    notes: "see recipe page",
    categories: "",
    keywords: ""
  },
  {
    name: "Onion infused oil",
    limit: "",
    isHigh: false,
    notes: "see recipe page",
    categories: "",
    keywords: ""
  },
  {
    name: "Acai powder",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Asafoetida powder",
    limit: "",
    isHigh: false,
    notes: "great onion substitute",
    categories: "",
    keywords: ""
  },
  {
    name: "Baking powder",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Baking soda",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cacao powder",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cocoa powder",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Cream",
    limit: "2 tablespoons",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Gelatine",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Ghee",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Icing sugar",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Lard",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Nutritional yeast",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Salt",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  },
  {
    name: "Soybean oil",
    limit: "",
    isHigh: false,
    notes: "",
    categories: "",
    keywords: ""
  }
];

export { data };

console.log(JSON.stringify(data, null, "  ").replace(/"([^"]+)":/g, '$1:'))