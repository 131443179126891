import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "@firebase/app";
import "@firebase/analytics";
import "@firebase/performance";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const firebaseConfig = {
  apiKey: "AIzaSyB90eerYnkZDDvrQt2KIPRQTxZK5MaJ-9s",
  authDomain: "fodmap-checker.firebaseapp.com",
  databaseURL: "https://fodmap-checker.firebaseio.com",
  projectId: "fodmap-checker",
  storageBucket: "fodmap-checker.appspot.com",
  messagingSenderId: "649972849410",
  appId: "1:649972849410:web:b59f4127d03cbfb74c001b",
  measurementId: "G-0383W36GZ1"
};

if (process.env.NODE_ENV === "production") {
  firebase.initializeApp(firebaseConfig);

  firebase.analytics();
  firebase.performance();
}