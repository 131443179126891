import React from "react";
import { colours } from "./colours";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const CheckRoot = styled.div`
  background-color: ${colours.positive[3]};
  color: ${colours.positive[9]};
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -0.75rem;
  right: 1rem;
  padding: 0.25rem;
  transform: rotate(5deg);
`;

const CrossRoot = styled.div`
  background-color: ${colours.negative[3]};
  color: ${colours.negative[9]};
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -0.75rem;
  right: 1rem;
  padding: 0.25rem;
  transform: rotate(5deg);
`;

export const SuitabilityIcon = ({ isHigh }) => {
  return isHigh ? (
    <CrossRoot>
      <ClearIcon />
    </CrossRoot>
  ) : (
    <CheckRoot>
      <CheckIcon />
    </CheckRoot>
  );
};
