import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import { data } from "./data";
import { colours } from "./colours";
import { SuitabilityIcon } from "./SuitabilityIcon";
import throttle from "lodash.throttle";

const ResultsList = styled.div`
  display: flex;
  width: 1080px;
  max-width: 90%;
  flex-wrap: wrap;
  padding: -2rem;
  justify-content: space-around;
`;

const ResultItem = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.2rem ${colours.greys[5]},
    0 0.2rem 0.2rem ${colours.greys[3]};
  padding: 1rem;
  margin: 1rem;
  min-width: 200px;
  max-width: 200px;
  position: relative;
`;

const Title = styled.div``;

const Notes = styled.div`
  margin-top: 1rem;
  color: grey;
`;

const indexedData = new Fuse(data, { keys: Object.keys(data[0]) });

const getSearchResults = searchTerm => searchTerm ? indexedData.search(searchTerm) : [];

const setResultsThrottled = throttle((set, term) => {
  setTimeout(() => {
    const results = getSearchResults(term);
    console.log(results)
    set(results);
  })
}, 500, { leading: false })

export const Results = ({ searchTerm }) => {
  const [results, setResults] = useState([]);
  useEffect(() => {
    setResultsThrottled(setResults, searchTerm);
  },[searchTerm])
  if (!searchTerm) return null;
  return (
    <ResultsList>
        {results.map((result) => (
        <ResultItem key={result.refIndex}>
            <Title>{result.item.name}</Title>
            <SuitabilityIcon {...result.item} />
            {(result.item.notes || result.item.limit) && <Notes>{result.item.notes} {result.item.limit && `(${result.item.limit})`}</Notes>}
        </ResultItem>
        ))}
    </ResultsList>
  );
};
